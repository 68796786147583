// Here you can add other styles
.format_paragrap {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.react-datepicker-popper {
  z-index: 10 !important;
}
